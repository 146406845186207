<template>
  <v-sheet
      :key="trigger"
      class="fill-height wsRoundedLight"
      color="transparent">

    <image-library-dialog
        @select="setImage"
        v-if="displayLibrary"
        v-model="displayLibrary"
    />
    <div v-if="element.text" class="fill-height">

      <v-sheet
          v-if="!CONTAIN_FULL_WIDTH"
          color="transparent"
          :style="imageStyle"
          class="fill-height"
      >
      </v-sheet>
        <ws-img ref="containerRef" :image-style="imageStyle" v-else :src="element.text" />
    </div>

    <ws-file-uploader
        v-else
        @success="editImage"
        disable-full-click
        class="fill-height"
        public
        :superadmin="template"
    >

      <template v-slot="{selectFile}">
        <v-sheet width="50" class="mx-auto" color="transparent">
          <ft-select
              @input="addImageAction($event, selectFile)"
              :items="addImageActionsSelect"
          >
            <v-btn  class="mx-auto"  :color="wsATTENTION" icon>
              <v-icon large   :color="wsATTENTION">mdi-plus-circle</v-icon>
            </v-btn>
          </ft-select>
        </v-sheet>

        <h5 v-if="(element.x2- element.x1) > 4 && (element.y2 - element.y1) > 4"
            :style="`color : ${wsACCENT}`"
            style="max-width: 180px"
            class="mt-2 mx-auto"
        >
          {{ $t('uploader.choose_image') }}
        </h5>

      </template>

    </ws-file-uploader>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
import imageLibraryDialog from "@/components/AvalonEditor/Dialogs/imageLibraryDialog";
export default {
  name: "elementImageEditor",
  components : {
    imageLibraryDialog
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    template : {
      type : Boolean,
      default : false
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      element : {},
      displayLibrary : false,
      trigger : 0,
      height : 0
    }
  },
  computed : {
    CONTAIN_FULL_WIDTH() {
      return this.element.config.contain && this.element.config.contain_full_width
    },
    CELLS_WIDTH() {
      return this.element[this.DIM_KEY['x2']] - this.element[this.DIM_KEY['x1']] +1
    },
    domElement() {
      return this.$refs.containerRef
    },
    iconColor() {
      return this.BLOCK_COLOR_PARAM(this.element , 'image_icon_color' , this.blockStyle) || 'black'
    },
    positionXTrigger() {
      if ( !this.element ) {
        return
      }
      if ( !this.element.config ) {
        return
      }
      if ( !this.element.config.image_position_x ) {
        return
      }
      return this.element.config[this.DIM_KEY['image_position_x']]
    },
    positionYTrigger() {
      if ( !this.element ) {
        return
      }
      if ( !this.element.config ) {
        return
      }
      if ( !this.element.config.image_position_y ) {
        return
      }
      return this.element.config[this.DIM_KEY['image_position_y']]
    },
    addImageActionsSelect() {
      return [
        { text : this.$t('Upload') , value : 'upload' , icon : 'mdi-upload' },
        { text : this.$t('BrowseCollection') , value : 'browse' , icon : 'mdi-grid' }
      ]
    },
    imageStyle() {
      let style = ''
      if ( this.IS_WESTUDY_ICON(this.element.text) ) {

        style += ` -webkit-mask: url('${this.element.text}') no-repeat ;
                    mask: url('${this.element.text}') no-repeat ${this.element.config[this.DIM_KEY['image_position_x']] ||50}% ${this.element.config[this.DIM_KEY['image_position_y']] || 50}%;
                    mask-size: ${this.element.config.contain ? 'contain' : 'cover'};
                    -webkit-mask-size: ${this.element.config.contain ? 'contain' : 'cover'};
                    position:relative;
                    background-color: ${this.iconColor} !important;
                    `
      } else {
        style +=  `background: url('${this.element.text}');
                 background-size : ${this.element.config.contain ? 'contain' : 'cover'};
                 background-repeat: no-repeat;
                 position:relative;`
      }




      let positionX = this.element.config.image_position_x || this.element.config.image_position_x === 0 ? this.element.config.image_position_x : 50
      let positionY = this.element.config.image_position_y || this.element.config.image_position_y === 0 ? this.element.config.image_position_y : 50

      if ( !this.MOBILE_VIEW ) {
        style += `background-position : ${positionX }% ${this.element.config.image_position_y || 50}%;`
      } else {
        style += `background-position : ${positionY}% ${this.element.config.image_position_sm_y || 50}%;`
      }

      if ( this.element.config.rounded ) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      style += this.GET_CLIPPING_MASK(this.element.config.mask || null , this.element.config.mask_resize)

      return style
    }
  },
  watch : {
    height(value) {
      this.updateHeight()
      if ( value > 0 ) {
        setTimeout(()=> {
          this.getHeight()
        } , 400)
      }
      setTimeout(  this.updateHeight , 300 )
    },


    CELLS_WIDTH() {
      this.getHeight()
    },
    iconColor() {
      this.trigger++
    },
    positionXTrigger() {
      this.trigger++
    },
    positionYTrigger() {
      this.trigger++
    },
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    },
    'element.text'(value , old) {
      if ( value !== old ) {
        setTimeout(()=>{this.trigger++ } , 10)
      }
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),
    getHeight() {
      if ( !this.CONTAIN_FULL_WIDTH ) {
        return
      }
      let domElement = this.$refs.containerRef
      if ( !domElement ) {
        return
      }
      let height = domElement.$el.clientHeight
      this.height = height
    },
    updateHeight() {
      this.$emit('actual-height-update' , this.height)
      if ( this.firstLoad ) {
        return
      }
      this.$emit('update-height', this.height )
    },
    addImageAction(value , selectFunction) {

      if ( value === 'upload') {
        selectFunction()
        return
      }

      this.displayLibrary = true

    },
    setImage(url) {
      this.element.text = url
      this.$emit('input',this.element)
      this.EDIT_ELEMENT(this.element)
    },
    async editImage($event) {
      this.element.text = $event.url
      this.$emit('input',this.element)
      this.EDIT_ELEMENT(this.element)
    }
  },
  mounted(){
    if ( this.value && !this.EQ(this.value , this.element) ) {
      this.element = this.COPY(this.value)
    }
  }
}
</script>

<style scoped>

</style>